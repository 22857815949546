import React from 'react'
import Logo from '../images/logo.jpeg'
import Hero1 from '../images/logoImage.jpeg'
import { SiSololearn } from 'react-icons/si'
import { FaTree } from 'react-icons/fa'
import { PiBrainBold } from 'react-icons/pi'
import { MdAutoFixHigh } from 'react-icons/md'
import { FaRegHeart } from 'react-icons/fa'
import InfoCard from './InfoCard'
import { IoIosArrowDropleft } from 'react-icons/io'
import { IoCall } from 'react-icons/io5'

export default function Hero() {
  return (
    <div>
      <div className=" bg-hero ">
        <div className="p-4">
          <img
            alt="image"
            className="w-48 rounded-full mt-4 hover:scale-105 duration-300 mx-auto border-8 border-secondary shadow-2xl"
            src={Logo}
          />
          <h1 className="text-5xl md:text-6xl text-center font-extrabold text-primary mt-12">מלי דיין</h1>
          <h1 className="text-2xl md:text-3xl text-center font-extrabold text-black mt-2">
            יועצת זוגית, מינית ומשפחתית
          </h1>
          <h3 className="text-2xl text-black md:text-4xl text-center font-light mt-2 ">
            להחזיר את התשוקה, לחזק את הקשר, ולבנות אינטימיות שתעמוד במבחן הזמן{' '}
          </h3>
          {/* <h2 className="text-4xl md:text-4xl text-center font-extrabold mt-2 ">שכל מאמן כושר חייב</h2> */}

          <div className="flex flex-col-reverse sm:flex-row mt-8  justify-center">
            <a
              href="#aboutus"
              className=" bg-transparent  m-2 px-10 py-4 text-lg border-2 border-black text-black font-extrabold rounded-full hover:scale-105 duration-300 text-center"
            >
              קצת עליי
            </a>
            <a
              href="#messageLeadForm"
              className=" text-center  bg-gradient-to-tr from-primary to-secondary m-2 s text-white px-10 py-4 text-lg font-extrabold rounded-full hover:scale-105 duration-300"
            >
              לשיחת ייעוץ{' '}
            </a>
          </div>
        </div>

        <div className="bg-gradient-to-t from-white to-transparent h-12"></div>
      </div>

      <div className="p-4  dotbg ">
        <div class="card p-6 max-w-[1000px] mx-auto">
          <div class="circle"></div>
          <div class="circle"></div>
          <div class="card-inner" dir="rtl">
            <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-primary font-extrabold mt-2">
              למה זוגיות?
            </h1>

            {/* <h3 dir="rtl" className=" md:text-2xl max-w-[1000px] mx-auto text-right px-4  mt-8">
                            זוגיות ואהבה תמיד מחוברים וקשורים זו לזו ובכל זאת, כל זוג עובר משברים שיכולים לחזק או להחליש אותו. למה זה קורה לנו? כי אנחנו אנשים שונים  שמגיעים מבתי גידול שונים - ערכים, אמונות ומודל זוגי שונה שראינו בבית ומצפים שכל זה יתחבר לזוגיות טובה ואוהבת ללא קשיים או אתגרים. רק שלאורך הזמן צצים ועולים הפערים ואנחנו מגלים שזוגיות טובה ואוהבת מצריכה מאיתנו מאמץ, עבודה והשקעה אישית. המודל הזוגי שלנו מגיע מההורים, מסרטי דיסני ורומנים שקראנו ואף אחד לא באמת לימד אותנו מהי זוגיות טובה עד שנכנסנו אליה והתחלנו ללמוד, להתפתח ולגדול תוך כדי לחוד וביחד.
                        </h3> */}
            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 mt-8">
              זוגיות ואהבה תמיד מחוברים וקשורים זו לזו ובכל זאת, כל זוג עובר משברים שיכולים לחזק או להחליש אותו.
            </p>

            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 my-4 font-bold">למה זה קורה לנו?</p>

            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 mt-2 ">
              כי אנחנו אנשים שונים שמגיעים מבתי גידול שונים - ערכים, אמונות ומודל זוגי שונה שראינו בבית ומצפים שכל זה
              יתחבר לזוגיות טובה ואוהבת ללא קשיים או אתגרים.
            </p>

            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 mt-2 ">
              רק שלאורך הזמן צצים ועולים הפערים ואנחנו מגלים שזוגיות טובה ואוהבת מצריכה מאיתנו מאמץ, עבודה והשקעה אישית.
            </p>

            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 mt-2 ">
              המודל הזוגי שלנו מגיע מההורים, מסרטי דיסני ורומנים שקראנו ואף אחד לא באמת לימד אותנו מהי זוגיות טובה עד
              שנכנסנו אליה והתחלנו ללמוד, להתפתח ולגדול תוך כדי לחוד וביחד.
            </p>

            <p className="md:text-2xl max-w-[1000px] mx-auto text-center px-4 mt-2 font-bold ">
              "אין כמו אהבה ואין כמו בית, ואין כמו אהבה שנותנת בית, ואין כמו בית שנותן אהבה."
            </p>
            <p className="md:text-xl text-primary text-sm max-w-[1000px] mx-auto text-center px-4 mt-2 font-extrabold ">
              עידית ברק{' '}
            </p>

            {/* <img alt="image" src={Hero1} className='md:max-w-[500px] object-contain hover:scale-105 duration-300 mx-auto m-4 my-8 rounded-3xl' /> */}
          </div>
        </div>

        {/* <h1 className="text-4xl md:text-5xl text-center font-extralight mt-8">מאנונימיים </h1>
                <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-[#B99976]  font-extrabold mt-2">למותג מצליח! </h1>

                <Image className=" justify-center md:max-w-[400px] hover:scale-105 duration-300 mx-auto" src={Phone} /> */}
      </div>

      <div id="messageLeadForm" className="flex justify-center my-12 text-white">
        <a
          href="tel:+972509933553"
          className="bg-brown text-center flex justify-center items-center from-primary m-2 to-secondary px-10 py-4 text-lg font-extrabold rounded-full hover:scale-105 duration-300"
        >
          <IoCall className="text-4xl mr-4" />
          <span className="text-xl md:text-2xl">חייגו אליי לשיחת ייעוץ</span>
        </a>
      </div>
      <div className="bg-gradient-to-tr bg-brown p-4 py-12">
        <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-white font-extrabold mt-12">
          למה יועצת זוגית?
        </h1>

        <div className="flex justify-center items-center mt-8 p-4 mx-8 ">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-2xl gap-8 max-w-[1200px]  ">
            <InfoCard icon={SiSololearn} title="כדי ללמוד" description="כלים איך לשפר את הזוגיות" />
            <InfoCard
              icon={FaTree}
              title="כדי להצמיח"
              description="את הזוגיות שלנו וליצור חיבורים ותחושות טובות יותר"
            />
            <InfoCard icon={PiBrainBold} title="כדי להבין" description="איך לצמוח באופן אישי בתוך הזוגיות" />
            <InfoCard icon={MdAutoFixHigh} title="כדי לשפר" description="את החיים שלנו ולחיות טוב יותר" />
            <InfoCard icon={FaRegHeart} title="כדי להפנים" description="שאתם לא לבד" />
          </div>
        </div>
      </div>
    </div>
  )
}
