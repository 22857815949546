import React from 'react'
import Guys from '../images/guys.jpg'
import Info1 from '../images/info1.png'
import { IoCall } from 'react-icons/io5'
import { MdOutlineDone } from 'react-icons/md'

export default function Info() {
  return (
    <div className="p-4 bg-brown py-8 rounded-t-3xl pb-12">
      <h1 dir="rtl" className="text-5xl md:text-6xl text-center text-white font-extrabold mt-2">
        ייעוץ מיני
      </h1>
      <h2 dir="rtl" className="text-3xl md:text-4xl text-center text-[#e8b3a3] font-bold mt-4">
        להחזיר את התשוקה, לחזק את הקשר, ולבנות אינטימיות שתעמוד במבחן הזמן.
      </h2>
      <div
        dir="rtl"
        className="mt-16 bg-brown text-white leading-8 md:text-3xl text-center max-w-[1200px] mx-auto px-6 bg-brown-800 rounded-3xl shadow-2xl p-8 border-4 border-[#e8b3a3]"
      >
        <h2 className="text-4xl md:text-5xl font-bold mb-8 text-white">האם אתם מרגישים כך?</h2>
        <ul className="list-none space-y-6 mb-8 font-medium">
          <li className="bg-brown p-4 rounded-xl shadow-md hover:bg-brown-600 transition-colors duration-300">
            "נמאס לי ליזום ולהיתקל בסירוב."
          </li>
          <li className="bg-brown p-4 rounded-xl shadow-md hover:bg-brown-600 transition-colors duration-300">
            "אנחנו כבר לא כמו שהיינו פעם. איפה התשוקה?"
          </li>
          <li className="bg-brown p-4 rounded-xl shadow-md hover:bg-brown-600 transition-colors duration-300">
            "יש בינינו פערים שאי אפשר לגשר עליהם. איך מתמודדים עם זה?"
          </li>
        </ul>
        <p className="mb-8 text-xl md:text-2xl">
          אם המשפטים האלו נשמעים לכם מוכרים, אתם לא לבד. רבים מתמודדים עם קשיים דומים. השגרה, הלחצים והפערים במיניות
          יכולים ליצור תחושה של ריחוק ואובדן חיבור.
        </p>
        <p className="text-2xl md:text-3xl font-bold text-[#e8b3a3]">אני כאן כדי להראות לכם שאפשר אחרת.</p>
      </div>

      <div className="mt-12 flex flex-col md:flex-row-reverse max-w-[1000px] rounded-3xl shadow-2xl mx-auto">
        <img
          alt="image"
          src={Guys || '/placeholder.svg'}
          className="md:max-w-[500px] border-8 border-white shadow-2xl object-contain hover:scale-105 duration-300 mx-auto m-4 rounded-3xl"
        />
        <div dir="rtl" className="px-4 m-4 md:m-8 mb-8">
          <h1 className="text-[#e8b3a3] md:text-right font-extrabold text-3xl md:text-4xl mt-4">
            איך אני יכולה לעזור לכם?
          </h1>

          <div className="mx-auto text-right md:text-right md:text-xl text-white mt-4">
            <h3 className="mt-4 font-bold text-[#e8b3a3]">לגשר על פערים במיניות</h3>
            <p>
              לא מעט זוגות מתמודדים עם הבדלים ברצונות ובצרכים המיניים. במקום לתת לפערים להתרחב, נלמד יחד איך להבין זה את
              זו, ליצור שיח אמיתי ולבנות אינטימיות שמספקת את שני הצדדים.
            </p>

            <h3 className="mt-4 font-bold text-[#e8b3a3]">להחזיר את התשוקה לזוגיות – במיוחד בזוגיות ארוכה</h3>
            <p>
              תשוקה היא לא רק ניצוץ של תחילת הדרך, אלא היכולת לשמור על חיבור עמוק לאורך שנים. יחד נחקור מה מרחיק ומה
              מדליק את התשוקה בזוגיות שלכם, ונבנה כלים שיעזרו לכם לשמר אותה.
            </p>

            <h3 className="mt-4 font-bold text-[#e8b3a3]">להתמודד עם שינויים ולהישאר קרובים</h3>
            <p>
              חיים משתנים – לידות, עומסים, גיל המעבר או שינויים אחרים יכולים להשפיע על הקשר האינטימי. בעזרת גישה אישית
              וממוקדת, נלמד איך להסתגל לשינויים ולשמור על חיבור רגשי ופיזי.
            </p>

            <h3 className="mt-4 font-bold text-[#e8b3a3]">שיח פתוח וכנה על מיניות</h3>
            <p>
              אולי הדבר הכי קשה הוא פשוט להתחיל לדבר על זה. במסגרת ייעוץ מיני, ניצור יחד מרחב בטוח שבו אפשר לדבר על כל
              מה שחשוב – רצונות, פחדים, פערים ופנטזיות.
            </p>

            <h3 className="mt-4 font-bold text-[#e8b3a3]">לבנות מחדש אינטימיות אחרי משברים</h3>
            <p>
              אם עברתם בגידה, משבר אמון או ריחוק רגשי, זה לא הסוף. בעזרת ליווי מקצועי ואמפתי, תוכלו לשקם את הקשר ולגלות
              מחדש את החיבור ביניכם.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-12 max-w-[800px] bg-white py-8 px-6 rounded-3xl shadow-2xl mx-auto">
        <div dir="rtl" className="px-4 mx-auto justify-center flex flex-col items-center m-4 md:m-8">
          <h1 className="text-primary text-center font-extrabold text-3xl md:text-4xl mt-4">למה דווקא אני?</h1>
          <ul className="mx-auto text-right md:text-xl text-black mt-4">
            <li className="mt-4">
              <span className="font-bold">גישה אמפתית ומכילה:</span> אני מבינה כמה קשה לפתוח את הנושא הזה, ואני כאן כדי
              ללוות אתכם ברגישות ובמקצועיות.
            </li>
            <li className="mt-4">
              <span className="font-bold">מומחיות מקצועית:</span> אני משלבת ניסיון עשיר וכלים מעשיים כדי לעזור לכם להגיע
              לשינוי אמיתי.
            </li>
            <li className="mt-4">
              <span className="font-bold">תהליך מותאם אישית:</span> כל זוג הוא ייחודי. התהליך נבנה בהתאם לצרכים ולמטרות
              שלכם.
            </li>
          </ul>
        </div>
      </div>

      <img
        alt="dd"
        src={Info1 || '/placeholder.svg'}
        className="md:max-w-[500px] border-8 border-white shadow-2xl object-contain hover:scale-105 duration-300 mx-auto m-4 my-16 rounded-3xl"
      />

      <h1 dir="rtl" className="text-4xl md:text-5xl text-center text-[#e8b3a3] font-extrabold my-8">
        מה אומרים לקוחות שעברו תהליך?
      </h1>
      <div dir="rtl" className="text-white leading-7 md:text-xl text-center max-w-[1000px] mx-auto px-4">
        <div className="space-y-8">
          <blockquote className="bg-brown-800 p-6 rounded-xl shadow-lg italic">
            "מלי היקרה, הכרנו לפני קצת יותר משנה, בתקופה מאוד מאתגרת בחיים שלנו. אני ובעלי היינו כמעט לפני קבלת החלטה
            שאנחנו מפרקים את כל מה שבנינו יחד. אין ספק שהשנה האחרונה הביאה איתה הרבה תהפוכות לחיינו, ואיזה מזל יש לנו
            שאת היית שם עבורנו להקשיב, לייעץ, לתמוך ובעיקר להגיד את המילה הנכונה! אנחנו רוצים להגיד לך תודה. תודה שהזכרת
            לנו למה התאהבנו אחד בשניה, תודה שלימדת אותנו לראות את הדברים הטובים ולקבל את הדברים הפחות טובים ותודה שהצלת
            לנו את הזוגיות. ❤️❤️❤️❤️"
          </blockquote>

          <blockquote className="bg-brown-800 p-6 rounded-xl shadow-lg italic">
            "אני רוצה להודות לך על התהליך והליווי שלך איתנו !!!! ראשית את אישה מאוד נעימה ושלווה מעבירה אנרגיות טובות
            ותחושת רוגע התחברנו אליך ישר!!!! שנית גרמת לנו לשנות 360 מעלות את הדעה שהיינו ממש שניה מלהתגרש גרמת לנו
            להבין הבנות כלים שעדיין עובדים ונעבוד עליהם ,גרמת לנו להתקדם קדימה מבלי לחזור אחורה עם כל הקושי ולנסות להגיע
            למקומות שלא היינו בהם !!!! מודה לך על כל התהליך ואני בטוחה ויודעת שאנחנו נחזור אליך כי זוגיות זה משהו שצריך
            לשמר לשמור ולעבוד כל הזמן !!!! מודה לך על הכל הצלת אותנו מגירושים 😘🥰😍"
          </blockquote>

          <blockquote className="bg-brown-800 p-6 rounded-xl shadow-lg italic">
            "היי מלי היקרה 💕 אני רוצה להודות לך מאוד על התהליך שעשינו איתך , למרות שעצרנו כרגע, לקחנו את כל מה שלימדת
            אותם ואנחנו ממשיכים ליישם את הדברים. לימדת אותנו להיות יותר פתוחים אחד עם השנייה , להבין שאנחנו פה ביחד אחד
            בשביל השנייה וצריך לעבוד על עצמנו בשביל להעצים את הזוגיות הזאת . אני למדתי להיות יותר פתוחה , לשתף יותר
            בקשיים שלי , לדעת שהפתיחות מובילה לפתרונות הבעיות ולא להיתקע עליהם עם עצמי. למדתי לשתף יותר על הרצונות
            המיניים שלי כי אחרת הצד השני לא ידע מה אני רוצה/אוהבת . כמובן שהעבודה ממשיכה תמיד , אבל בהחלט מרגישה שיש
            שיפור משמעותי בזוגיות שלנו מאז התהליך גם בפן האישי עם עצמי וגם הזוגי . שמחה כל כך שעשינו את התהליך הזה
            ומקווה שמפה נתעצם ונהיה טובים יותר ! תודה לך 🙏🏼🙏🏼❤️❤️❤️"
          </blockquote>
        </div>
      </div>

      <h1 className="text-3xl md:text-4xl text-center text-white font-extrabold mt-8">אל תחכו שמשהו יישבר</h1>
      <p dir="rtl" className="text-xl md:text-2xl text-center text-white mt-4 max-w-[800px] mx-auto">
        אם אתם מרגישים שהזוגיות שלכם יכולה להיות טובה יותר – זה הזמן לפעול. אל תוותרו על החיבור ועל התשוקה שיכולים להיות
        חלק בלתי נפרד מהחיים שלכם.
      </p>

      <div className="flex justify-center my-12 text-white">
        <a
          href="tel:+972509933553"
          className="bg-brown text-center flex justify-center items-center from-primary m-2 border-2 shadow-2xl to-secondary px-10 py-4 text-lg font-extrabold rounded-full hover:scale-105 duration-300"
        >
          <IoCall className="text-4xl mr-4" />
          <span className="text-lg md:text-xl"> לשיחת ייעוץ ראשונית ללא עלות</span>
        </a>
      </div>
    </div>
  )
}
